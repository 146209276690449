import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, styled, Grid, Typography, Tooltip, Skeleton, Button} from '@mui/material';
import {ArrowBackRounded} from '@mui/icons-material';

import 'src/App.css';


const TitleWrapper = styled(Box)(
  ({ theme }) => `
      border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      box-shadow: 0px 0px 0px 1px #e0e0e0;
      height: 120px;
      margin: -22px -27px 20px;
      padding: 40px 24px;

      .customer-add-btn {
        text-align: right;
      }
      .back-arrow {
        color: ${theme.palette.primary.dark};
      }

      @media screen and (max-width: 959px) {
        height: auto; 
        width: 100vw;
 
        .customer-add-btn {
          text-align: center;
          .MuiButton-root {
            width: 90vw !important;
          }
        }

        .MuiTypography-root.MuiTypography-h4 {
          font-size: 1.5rem;
        }
      }
      `
);

function PageTitle({title, isLoading, isError}) {

  return (
    <TitleWrapper>
        <Grid container spacing={2}>
          <Grid className="customer-accounts-title" display='flex' alignItems='center' item xs={12}>
            <Tooltip arrow title="Go Back"><Link reloadDocument to="/"><ArrowBackRounded className="back-arrow" fontSize='large' sx={{marginRight: '20px', marginTop: '8px'}}/></Link></Tooltip>
            {isError ? 
              <>
                <Typography variant='body2' color='text.secondary' display='inline' mr='10px'>Account failed to load, please try again.</Typography>
                <Button size="small" variant="contained" color="primary" onClick={() => window.location.reload(false)}>Retry</Button>
              </>
              :
              <Typography variant="h4" color="primary.dark" display='inline'>{isLoading ? <Skeleton width='200px' sx={{display: 'inline-flex'}}/> : title}</Typography>
            }
          </Grid>
        </Grid>
    </TitleWrapper>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
}

export default PageTitle;